<template>
  <div class="row content-wrapper">
    <LoadingSpinner v-if="isLoading" />
    <template v-else>
      <div>
        <div class="profile-top-section">
          <h1 class="profile-title">프로필</h1>
          <div class="profile-content">
            <div class="profile-line">
              <span class="profile-label">계정</span>
              <span class="profile-text">{{ currentUser.email }}</span>
              <a
                href="#"
                class="btn btn-default logout-btn"
                @click.prevent="onClickLogout"
                >로그아웃</a
              >
            </div>
            <div class="profile-line">
              <span class="profile-label">닉네임</span>
              <span class="profile-text">{{ currentUser.nickname }}</span>
            </div>
            <div class="clearfix profile-line">
              <div>
                <a
                  href="#"
                  class="btn btn-default unregister-btn"
                  @click.prevent="onClickUnregister"
                  >회원탈퇴</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="profile-bottom-section">
          <h1 class="profile-title">북마크</h1>
          <div class="row profile-content">
            <template v-if="userBookmarkSpots.length">
              <div
                class="col-md-4 content-list-wrapper"
                v-for="spot in userBookmarkSpots"
                :key="spot.id"
              >
                <div class="content-item-wrapper">
                  <router-link :to="`/destination/${spot.id}?page=${page}`">
                    <img
                      :src="spot.thumbnail"
                      :alt="spot.name"
                      class="list-thumbnail"
                    />
                    <h4 class="spot-title">{{ spot.name }}</h4>
                    <p class="spot-desc">{{ spot.summary }}</p>
                  </router-link>
                </div>
              </div>
            </template>
            <template v-else>
              <h5 class="text-center">아직 북마크한 여행지가 없어요.</h5>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LoadingSpinner from '@/components/common/LoadingSpinner.vue';

export default {
  data() {
    return {
      isLoading: false,
      nickname: '',
      isChangeNickname: false,
    };
  },
  components: { LoadingSpinner },
  computed: {
    ...mapState(['currentUser', 'userBookmarkSpots']),
  },
  created() {
    this.fetchData();
  },
  methods: {
    ...mapActions([
      'FETCH_USER',
      'FETCH_BOOKMARKS_SPOT_LIST',
      'LOGOUT',
      'UNREGISTER',
      'UPDATE_USER',
    ]),
    fetchData() {
      // const userId = this.$store.state.userId;
      const userEmail = this.$store.state.email;
      console.log(userEmail);

      this.isLoading = true;
      this.FETCH_USER(userEmail);
      this.FETCH_BOOKMARKS_SPOT_LIST(userEmail);
      this.nickname = this.currentUser.nickname;
      this.isLoading = false;
    },
    onClickLogout() {
      this.LOGOUT();
      this.$router.push('/');
    },
    onClickUnregister() {
      const confirmUnregister = confirm('정말 탈퇴하시겠습니까?');
      if (confirmUnregister) {
        this.UNREGISTER(this.$store.state.userId);
        this.$router.push('/');
      } else {
        return false;
      }
    },
    onClickChangeNickname() {
      this.isChangeNickname = true;
    },
    onClickChangeNicknameDone() {
      const userData = {
        id: this.$store.state.userId,
        nickname: this.nickname,
      };
      this.UPDATE_USER(userData);
      this.isChangeNickname = false;
    },
    onClickProfileNav() {
      this.$refs.bookmarksNav.classList.remove('active');
      this.$refs.bookmarksContent.classList.remove('active', 'show');
      this.$refs.profileNav.classList.add('active');
      this.$refs.profileContent.classList.add('active', 'show');
    },
    onClickBookmarksNav() {
      this.$refs.profileNav.classList.remove('active');
      this.$refs.profileContent.classList.remove('active', 'show');
      this.$refs.bookmarksNav.classList.add('active');
      this.$refs.bookmarksContent.classList.add('active', 'show');
    },
  },
};
</script>

<style scoped>
.spot-title {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 700;
  color: #333;
}
.spot-desc {
  margin-bottom: 0;
  font-size: 16px;
  color: #333;
  word-break: keep-all;
}
.content-item-wrapper {
  margin-bottom: 50px;
}
.list-thumbnail {
  border-radius: 5px;
}
.profile-title {
  padding-bottom: 18px;
  font-size: 30px;
  color: #333;
  border-bottom: 0.5px solid #c8c8c8;
}
.profile-line {
  padding: 20px 0;
  border-bottom: 0.5px solid #c8c8c8;
}
.profile-line:last-child {
  border: 0;
}
.profile-content {
}
.profile-label {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  color: #333;
  min-width: 100px;
}
.profile-text {
  font-size: 20px;
  color: #333;
}
.logout-btn {
  float: right;
  width: 90px;
  background-color: #333;
  color: #fff;
}
.unregister-btn {
  float: right;
  width: 90px;
  background-color: #f4f4f4;
  color: #333;
}
.profile-top-section {
  border-bottom: 20px solid #f4f4f4;
}
.profile-bottom-section {
  padding-top: 50px;
}
.profile-bottom-section .profile-title {
  border: 0;
  padding-bottom: 50px;
}
</style>
