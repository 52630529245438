<template>
  <ProfileComponent />
</template>

<script>
import ProfileComponent from '@/components/profile/ProfileComponent.vue';

export default {
  components: {
    ProfileComponent,
  },
};
</script>

<style></style>
